//portlets
!function($) {
    "use strict";

    /**
    Portlet Widget
    */
    var Portlet = function() {
        this.$body = $("body"),
        this.$portletIdentifier = ".portlet",
        this.$portletCloser = '.portlet a[data-toggle="remove"]',
        this.$portletRefresher = '.portlet a[data-toggle="reload"]'
    };

    //on init
    Portlet.prototype.init = function() {
        // Panel closest
        var $this = this;
        $(document).on("click",this.$portletCloser, function (ev) {
            ev.preventDefault();
            var $portlet = $(this).closest($this.$portletIdentifier);
                var $portlet_parent = $portlet.parent();
            $portlet.remove();
            if ($portlet_parent.children().length == 0) {
                $portlet_parent.remove();
            }
        });

        // Panel Reload
        $(document).on("click",this.$portletRefresher, function (ev) {
            ev.preventDefault();
            var $portlet = $(this).closest($this.$portletIdentifier);
            // This is just a simulation, nothing is going to be reloaded
            $portlet.append('<div class="panel-disabled"><div class="loader-1"></div></div>');
            var $pd = $portlet.find('.panel-disabled');
            setTimeout(function () {
                $pd.fadeOut('fast', function () {
                    $pd.remove();
                });
            }, 500 + 300 * (Math.random() * 5));
        });
    },
    //
    $.Portlet = new Portlet, $.Portlet.Constructor = Portlet

}(window.jQuery),

/**
 * Notifications
 */
function($) {
    "use strict";

    var Notification = function() {};

    //simple notificaiton
    Notification.prototype.notify = function(style, position, title, text) {
        var icon = 'fa fa-adjust';
        if(style == "error"){
            icon = "fa fa-exclamation";
        }else if(style == "warning"){
            icon = "fa fa-warning";
        }else if(style == "success"){
            icon = "fa fa-check";
        }else if(style == "custom"){
            icon = "md md-album";
        }else if(style == "info"){
            icon = "fa fa-question";
        }else{
            icon = "fa fa-adjust";
        }
        $.notify({
            title: title,
            text: text,
            image: "<i class='"+icon+"'></i>"
        }, {
            style: 'metro',
            className: style,
            globalPosition:position,
            showAnimation: "show",
            showDuration: 0,
            hideDuration: 1000,
            autoHide: true,
            clickToHide: true,
	    gap: 100
        });
    },

    //auto hide notification
    Notification.prototype.autoHideNotify = function (style,position, title, text) {
        var icon = "fa fa-adjust";
        if(style == "error"){
            icon = "fa fa-exclamation";
        }else if(style == "warning"){
            icon = "fa fa-warning";
        }else if(style == "success"){
            icon = "fa fa-check";
        }else if(style == "custom"){
            icon = "md md-album";
        }else if(style == "info"){
            icon = "fa fa-question";
        }else{
            icon = "fa fa-adjust";
        }
        $.notify({
            title: title,
            text: text,
            image: "<i class='"+icon+"'></i>"
        }, {
            style: 'metro',
            className: style,
            globalPosition:position,
            showAnimation: "show",
            showDuration: 0,
            hideDuration: 0,
            autoHideDelay: 5000,
            autoHide: true,
            clickToHide: true
        });
    },
    //confirmation notification
    Notification.prototype.confirm = function(style,position, title) {
        var icon = "fa fa-adjust";
        if(style == "error"){
            icon = "fa fa-exclamation";
        }else if(style == "warning"){
            icon = "fa fa-warning";
        }else if(style == "success"){
            icon = "fa fa-check";
        }else if(style == "custom"){
            icon = "md md-album";
        }else if(style == "info"){
            icon = "fa fa-question";
        }else{
            icon = "fa fa-adjust";
        }
        $.notify({
            title: title,
            text: 'Are you sure you want to do nothing?<div class="clearfix"></div><br><a class="btn btn-sm btn-white yes">Yes</a> <a class="btn btn-sm btn-danger no">No</a>',
            image: "<i class='"+icon+"'></i>"
        }, {
            style: 'metro',
            className: style,
            globalPosition:position,
            showAnimation: "show",
            showDuration: 0,
            hideDuration: 0,
            autoHide: false,
            clickToHide: false
        });
        //listen for click events from this style
        $(document).on('click', '.notifyjs-metro-base .no', function() {
          //programmatically trigger propogating hide event
          $(this).trigger('notify-hide');
        });
        $(document).on('click', '.notifyjs-metro-base .yes', function() {
          //show button text
          alert($(this).text() + " clicked!");
          //hide notification
          $(this).trigger('notify-hide');
        });
    },
    //init - examples
    Notification.prototype.init = function() {

    },
    //init
    $.Notification = new Notification, $.Notification.Constructor = Notification
    
    
    
    tinyMCE.init({
	selector: '.wysiwyg-simple',
	theme: "modern",
	editor_deselector: "readonly",
	menubar:false,
	statusbar: false,
	height:200,
	paste_as_text: true,
	plugins: [
	    "paste"
	],
	setup: function()
	{
	    //tinymce.activeEditor.getBody().setAttribute('contenteditable', false);
	    if ( $('#' + this.id).attr('readonly') )
	    {
		this.settings.readonly = true;
	    }
	    //

	},
	toolbar: "undo redo | bold | link"
    });

    tinyMCE.init({
	selector: '.wysiwyg',
	theme: "modern",
	editor_deselector: "readonly",
	menubar:false,
	statusbar: false,
	height:40,
	paste_as_text: true,
	valid_elements: "p,br",
	plugins: [
	    "paste"
	],
	toolbar: false
    });


    $('button.delete-button').on('click',function( e ){
    
	e.preventDefault();
	
	$('input[name="_method"]').val('DELETE');
	$('#delete-modal').modal('toggle');
	$('form[name="form-data"]').submit();
	
	return false;
    });
    
    
    
}(window.jQuery),

/**
 * Components
 */
function($) {
    "use strict";

    var Components = function() {};

    //initializing tooltip
    Components.prototype.initTooltipPlugin = function() {
        $.fn.tooltip && $('[data-toggle="tooltip"]').tooltip()
    },

    //initializing popover
    Components.prototype.initPopoverPlugin = function() {
        $.fn.popover && $('[data-toggle="popover"]').popover()
    },

    //initializing custom modal
    Components.prototype.initCustomModalPlugin = function() {
        $('[data-plugin="custommodal"]').on('click', function(e) {
        	Custombox.open({
                target: $(this).attr("href"),
                effect: $(this).attr("data-animation"),
                overlaySpeed: $(this).attr("data-overlaySpeed"),
                overlayColor: $(this).attr("data-overlayColor")
            });
        	e.preventDefault();
        });
    },

    //initializing nicescroll
    Components.prototype.initNiceScrollPlugin = function() {
        //You can change the color of scroll bar here
        $.fn.niceScroll &&  $(".nicescroll").niceScroll({ cursorcolor: '#98a6ad',cursorwidth:'6px', cursorborderradius: '5px'});
    },

    //range slider
    Components.prototype.initRangeSlider = function() {
        $.fn.slider && $('[data-plugin="range-slider"]').slider({});
    },

    /* -------------
     * Form related controls
     */
    //switch
    Components.prototype.initSwitchery = function() {
        $('[data-plugin="switchery"]').each(function (idx, obj) {
            new Switchery($(this)[0], $(this).data());
        });
    },
    //multiselect
    Components.prototype.initMultiSelect = function() {
        if($('[data-plugin="multiselect"]').length > 0)
            $('[data-plugin="multiselect"]').multiSelect($(this).data());
    },

     /* -------------
     * small charts related widgets
     */
     //peity charts
     Components.prototype.initPeityCharts = function() {
        $('[data-plugin="peity-pie"]').each(function(idx, obj) {
            var colors = $(this).attr('data-colors')?$(this).attr('data-colors').split(","):[];
            var width = $(this).attr('data-width')?$(this).attr('data-width'):20; //default is 20
            var height = $(this).attr('data-height')?$(this).attr('data-height'):20; //default is 20
            $(this).peity("pie", {
                fill: colors,
                width: width,
                height: height
            });
        });
        //donut
         $('[data-plugin="peity-donut"]').each(function(idx, obj) {
            var colors = $(this).attr('data-colors')?$(this).attr('data-colors').split(","):[];
            var width = $(this).attr('data-width')?$(this).attr('data-width'):20; //default is 20
            var height = $(this).attr('data-height')?$(this).attr('data-height'):20; //default is 20
            $(this).peity("donut", {
                fill: colors,
                width: width,
                height: height
            });
        });

         $('[data-plugin="peity-donut-alt"]').each(function(idx, obj) {
            $(this).peity("donut");
        });

         // line
         $('[data-plugin="peity-line"]').each(function(idx, obj) {
            $(this).peity("line", $(this).data());
         });

         // bar
         $('[data-plugin="peity-bar"]').each(function(idx, obj) {
            
            
            var colors = $(this).attr('data-colors')?$(this).attr('data-colors').split(","):[];
            var width = $(this).attr('data-width')?$(this).attr('data-width'):20; //default is 20
            var height = $(this).attr('data-height')?$(this).attr('data-height'):20; //default is 20
            $(this).peity("bar", {
                fill: colors,
                width: width,
                height: height
            });
         });
     },


    //initilizing
    Components.prototype.init = function() {
        var $this = this;
        this.initTooltipPlugin(),
        this.initPopoverPlugin(),
        this.initNiceScrollPlugin(),
        this.initCustomModalPlugin(),
        this.initRangeSlider(),
        this.initSwitchery(),
        this.initMultiSelect(),
        this.initPeityCharts(),
        //creating portles
        $.Portlet.init();
        
    },

    $.Components = new Components, $.Components.Constructor = Components

    $('.count-length').maxlength({
		alwaysShow: true,
		placement: 'bottom-left'
    });
      
    //Lnaguage tabs
    $('ul.lang-tabs li.tab .checkbox .lang').on('change', function(){
	
	var lang = $(this).closest('li.tab').data('rel'),
	    val = this.checked ? true : false;
	
	if ( $(this).hasClass('default-lang') && !val )
	{
	    $.Notification.notify('warning', 'bottom right', 'Warning!', 'This language is default one!') ;
	}
	
	$('._lang_stage_' + lang ).fadeToggle(val);
    
    });
    
        //Lnaguage tabs
    $('.remove-main-image').on('click', function(e){
	
	e.preventDefault();
	
	var id = $('input[name="recordid"]').val();
	
	if ( pageModule == undefined || id <= 0 )
	    return false;
	
	swal({   
            title: "Are you sure?",   
            text: "Are you sure you want to delete this image?",   
            type: "warning",
	    timer: 10000,
            showCancelButton: true,   
            confirmButtonColor: "#DD6B55",   
            confirmButtonText: "Yes, delete it!",   
            cancelButtonText: "No, cancel!",   
            closeOnConfirm: false,   
            closeOnCancel: true,
	    showLoaderOnConfirm: true
        }, function(isConfirm){   
            
	    if (isConfirm)
	    {     
                
		var CSRF_TOKEN = $('meta[name="csrf-token"]').attr('content');

		$.ajax({
		    url: site_url + pageModule +'/main-image',
		    type: 'DELETE',
		    data: { '_token': CSRF_TOKEN, 'pageModule': pageModule, 'recordid': id },
		    dataType: 'json',
		    success: function (data)
		    {
			if ( data.result )
			{
			    swal("Deleted!", "This image is removed!", "success");   
			    $('.previewMainImageBox').fadeOut().remove();
			    
			}
			else
			{
			    $.Notification.notify('warning','bottom right','Not ok', 'Something goes wrong!');
			}
		    }
		});
	
	
            }
        });
     
	return false;
    });
    
    
    //Work with the Online/Offline button
    $(document).on('click', 'button.btnActiveStage', function(){
    
    	var active = ( $('input[name="active"]').val()  == 1 ) ? 0 : 1;
	
	$('input[name="active"]').val( active );
	
	$('input[name="active"]').closest('form').submit();
	
    });
    
    
    
    
}(window.jQuery),
    //initializing main application module
function($) {
    "use strict";
    $.Components.init();
}(window.jQuery);

